var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.rechargeVariable.class},[_c('div',{staticClass:"wrap"},[(_vm.cardBagShow)?_c('div',{staticClass:"card-bag",on:{"click":_vm.toCardBag}},[_vm._v("我的卡包")]):_vm._e(),_c('div',{staticClass:"form"},[_c('div',{staticClass:"form-content"},[_c('van-form',{ref:"form",on:{"submit":_vm.subSuccess}},[_c('ul',{class:_vm.rechargeType == 2 || _vm.rechargeType == 3
                ? 'form-ul css-2'
                : 'form-ul'},[_c('li',[_c('van-field',{attrs:{"placeholder":"请输入兑换码","rules":_vm.rules.code,"maxlength":16,"label":"兑换码："},on:{"blur":_vm.redeemChange},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('li',[_c('van-field',{ref:"focusinput",attrs:{"placeholder":_vm.rechargeType == 2 || _vm.rechargeType == 3 || _vm.rechargeType == 7
                    ? '请输入淘宝绑定的手机号'
                    : _vm.rechargeType == 5
                    ? '请输入加油卡号'
                    : _vm.rechargeType == 11
                    ? '请输入京东用户名'
                    : _vm.rechargeType == 1 || _vm.rechargeType == 4
                    ? '请输入手机号'
                    : _vm.rechargeType == 10
                    ? '请输入充值手机号'
                    : '请输入充值账号',"rules":_vm.rules.phone,"label":_vm.rechargeType == 2 || _vm.rechargeType == 3 || _vm.rechargeType == 7
                    ? '手机号：'
                    : _vm.rechargeType == 5
                    ? '加油卡号：'
                    : _vm.rechargeType == 11
                    ? '京东账号：'
                    : _vm.rechargeType == 1 ||
                      _vm.rechargeType == 4 ||
                      _vm.rechargeType == 8
                    ? '手机号：'
                    : _vm.rechargeType == 10
                    ? '充值手机号'
                    : '充值账号：',"type":_vm.rechargeType == 1 || _vm.rechargeType == 4 ? 'tel' : 'text',"maxlength":_vm.rechargeType == 5
                    ? 19
                    : _vm.rechargeType == 1 || _vm.rechargeType == 4
                    ? '11'
                    : 'max'},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('li',{staticClass:"tow-para"},[_c('van-field',{attrs:{"placeholder":"请输入验证码","rules":_vm.rules.captcha,"label":"验证码：","maxlength":5},model:{value:(_vm.form.captcha),callback:function ($$v) {_vm.$set(_vm.form, "captcha", $$v)},expression:"form.captcha"}}),_c('div',{staticClass:"btn btn-img",on:{"click":_vm.getImgCode}},[_c('img',{attrs:{"src":_vm.captchaImg.captchaBase64,"alt":""}})])],1)])])],1),_c('div',{class:_vm.subBtnDisable ? 'btn-sub is-disable' : 'btn-sub',on:{"click":_vm.submit}},[_vm._v(" 立即兑换 ")])]),_c('RechargeRule')],1),_c('van-dialog',{staticClass:"cus-dialog",attrs:{"showConfirmButton":false},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('h3',{staticClass:"title"},[_vm._v("温馨提示！")]),_c('div',{staticClass:"msg"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.dialog.msg)}}),(
          _vm.dialog.ulMsgShow &&
            _vm.dialog.redeCodeStatus == 2 &&
            _vm.dialog.gcAttribute == 3
        )?_c('ul',[_c('li',[_c('span',[_vm._v("充值商品：")]),_c('em',[_vm._v(_vm._s(_vm.dialog.goodsName))])]),_c('li',[_c('span',[_vm._v("充值账号：")]),_c('em',[_vm._v(_vm._s(_vm.dialog.account))])]),_c('li',[_c('span',[_vm._v("充值时间：")]),_c('em',[_vm._v(_vm._s(this.common.formatDate(_vm.dialog.rechargeTime, '{y}-{m}-{d}')))])])]):_vm._e(),(
          _vm.dialog.ulMsgShow &&
            _vm.dialog.redeCodeStatus === 2 &&
            _vm.dialog.gcAttribute != 3
        )?_c('ul',[_c('li',[_c('span',[_vm._v("兑换商品：")]),_c('em',[_vm._v(_vm._s(_vm.dialog.goodsName))])]),_c('li',[_c('span',[_vm._v("手机号码：")]),_c('em',[_vm._v(_vm._s(_vm.dialog.account))])]),_c('li',[_c('span',[_vm._v("兑换时间：")]),_c('em',[_vm._v(_vm._s(this.common.formatDate(_vm.dialog.excTime, '{y}-{m}-{d}')))])])]):_vm._e(),(_vm.dialog.ulMsgShow && _vm.dialog.redeCodeStatus == 3)?_c('ul',[_c('li',[_c('span',[_vm._v("过期时间：")]),_c('em',[_vm._v(_vm._s(this.common.formatDate(_vm.dialog.expireTime, '{y}-{m}-{d}')))])])]):_vm._e()]),_c('div',{staticClass:"confirm",on:{"click":_vm.dialogSure}},[_vm._v("确认")])]),_c('van-dialog',{staticClass:"cus-dialog",attrs:{"showConfirmButton":false},model:{value:(_vm.promptVisible),callback:function ($$v) {_vm.promptVisible=$$v},expression:"promptVisible"}},[_c('h3',{staticClass:"title"},[_vm._v("温馨提示！")]),_c('div',{staticClass:"msg"},[_vm._v("请确认充值账户，确认提交后不退不换")]),_c('div',{staticClass:"operations"},[_c('div',{staticClass:"confirm",staticStyle:{"margin-right":"10px"},on:{"click":_vm.submitConfirm}},[_vm._v(" 确认 ")]),_c('div',{staticClass:"confirm cancel",on:{"click":function($event){_vm.promptVisible = false}}},[_vm._v("取消")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }