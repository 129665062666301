<template>
  <div :class="rechargeVariable.class">
    <div class="wrap">
      <div class="card-bag" v-if="cardBagShow" @click="toCardBag">我的卡包</div>
      <div class="form">
        <div class="form-content">
          <van-form ref="form" @submit="subSuccess">
            <ul
              :class="
                rechargeType == 2 || rechargeType == 3
                  ? 'form-ul css-2'
                  : 'form-ul'
              "
            >
              <li>
                <van-field
                  v-model="form.code"
                  placeholder="请输入兑换码"
                  :rules="rules.code"
                  :maxlength="16"
                  label="兑换码："
                  @blur="redeemChange"
                ></van-field>
              </li>
              <li>
                <van-field
                  ref="focusinput"
                  v-model="form.phone"
                  :placeholder="
                    rechargeType == 2 || rechargeType == 3 || rechargeType == 7
                      ? '请输入淘宝绑定的手机号'
                      : rechargeType == 5
                      ? '请输入加油卡号'
                      : rechargeType == 11
                      ? '请输入京东用户名'
                      : rechargeType == 1 || rechargeType == 4
                      ? '请输入手机号'
                      : rechargeType == 10
                      ? '请输入充值手机号'
                      : '请输入充值账号'
                  "
                  :rules="rules.phone"
                  :label="
                    rechargeType == 2 || rechargeType == 3 || rechargeType == 7
                      ? '手机号：'
                      : rechargeType == 5
                      ? '加油卡号：'
                      : rechargeType == 11
                      ? '京东账号：'
                      : rechargeType == 1 ||
                        rechargeType == 4 ||
                        rechargeType == 8
                      ? '手机号：'
                      : rechargeType == 10
                      ? '充值手机号'
                      : '充值账号：'
                  "
                  :type="
                    rechargeType == 1 || rechargeType == 4 ? 'tel' : 'text'
                  "
                  :maxlength="
                    rechargeType == 5
                      ? 19
                      : rechargeType == 1 || rechargeType == 4
                      ? '11'
                      : 'max'
                  "
                ></van-field>
              </li>
              <li class="tow-para">
                <van-field
                  v-model="form.captcha"
                  placeholder="请输入验证码"
                  :rules="rules.captcha"
                  label="验证码："
                  :maxlength="5"
                ></van-field>
                <div class="btn btn-img" @click="getImgCode">
                  <img :src="captchaImg.captchaBase64" alt="" />
                </div>
              </li>
            </ul>
          </van-form>
        </div>
        <div
          :class="subBtnDisable ? 'btn-sub is-disable' : 'btn-sub'"
          @click="submit"
        >
          立即兑换
        </div>
        <!-- <img
          v-if="+rechargeType === 2"
          class="taoCoyp"
          src="@/assets/img/copytao.png"
        /> -->
      </div>
      <!-- 规则 -->
      <RechargeRule />
    </div>

    <van-dialog
      v-model="dialog.show"
      :showConfirmButton="false"
      class="cus-dialog"
    >
      <h3 class="title">温馨提示！</h3>
      <div class="msg">
        <p v-html="dialog.msg"></p>
        <ul
          v-if="
            dialog.ulMsgShow &&
              dialog.redeCodeStatus == 2 &&
              dialog.gcAttribute == 3
          "
        >
          <li>
            <span>充值商品：</span>
            <em>{{ dialog.goodsName }}</em>
          </li>
          <li>
            <span>充值账号：</span>
            <em>{{ dialog.account }}</em>
          </li>
          <li>
            <span>充值时间：</span>
            <em>{{
              this.common.formatDate(dialog.rechargeTime, '{y}-{m}-{d}')
            }}</em>
          </li>
        </ul>
        <ul
          v-if="
            dialog.ulMsgShow &&
              dialog.redeCodeStatus === 2 &&
              dialog.gcAttribute != 3
          "
        >
          <li>
            <span>兑换商品：</span>
            <em>{{ dialog.goodsName }}</em>
          </li>
          <li>
            <span>手机号码：</span>
            <em>{{ dialog.account }}</em>
          </li>
          <li>
            <span>兑换时间：</span>
            <em>{{ this.common.formatDate(dialog.excTime, '{y}-{m}-{d}') }}</em>
          </li>
        </ul>
        <ul v-if="dialog.ulMsgShow && dialog.redeCodeStatus == 3">
          <li>
            <span>过期时间：</span>
            <em>{{
              this.common.formatDate(dialog.expireTime, '{y}-{m}-{d}')
            }}</em>
          </li>
        </ul>
      </div>
      <div class="confirm" @click="dialogSure">确认</div>
    </van-dialog>

    <van-dialog
      v-model="promptVisible"
      :showConfirmButton="false"
      class="cus-dialog"
    >
      <h3 class="title">温馨提示！</h3>
      <div class="msg">请确认充值账户，确认提交后不退不换</div>
      <div class="operations">
        <div class="confirm" style="margin-right: 10px;" @click="submitConfirm">
          确认
        </div>
        <div class="confirm cancel" @click="promptVisible = false">取消</div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { checkCode, getCaptcha, bindAccount } from '@/api'
import RechargeRule from '@/views/components/RechargeRule.vue'
export default {
  components: { RechargeRule },
  data() {
    const phoneVal = val => {
      if (this.rechargeType == 2 || this.rechargeType == 3) {
        return true
      } else if (this.rechargeType == 5) {
        if (!/^1000[a-zA-Z0-9]{15}$/.test(val)) {
          this.rules.phone[0].message = '请输入1000开头的19位卡号'
        }
        return /^1000[a-zA-Z0-9]{15}$/.test(val)
      } else if (
        this.rechargeType == 1 ||
        this.rechargeType == 4 ||
        this.rechargeType == 8 ||
        this.rechargeType == 10
      ) {
        return /^1[3456789]\d{9}$/.test(val)
      } else {
        return true
      }
    }
    return {
      cardBagShow: this.$route.query.cb == 1,
      textReplace: this.$route.query.t,
      rechargeType: this.$route.query.type,
      // 1.话费充值;2.天猫购物券;3:天猫超市享淘卡;4:饿了么吃货卡;5:中国石化加油卡;6公共；7:天猫优惠券兑换;8:爱奇艺;9:优酷；10:腾讯；11:京东；12同行直充；13京东直充；14同行兑换码
      rechargeVariable: {
        class: '',
        name: ''
      },
      form: {
        code: this.$route.query.code || undefined,
        phone: '',
        captcha: '',
        token: ''
      },
      rules: {
        code: [{ required: true, message: '请输入兑换码' }],
        phone: [
          {
            validator: phoneVal,
            message:
              this.$route.query.type == 2 ||
              this.$route.query.type == 3 ||
              this.$route.query.type == 7
                ? '请输入淘宝账号'
                : this.$route.query.type == 5
                  ? '请输入加油卡号'
                  : this.$route.query.type == 11
                    ? '请输入京东账号'
                    : this.$route.query.type == 1 ||
                  this.$route.query.type == 4 ||
                  this.$route.query.type == 8 ||
                  this.$route.query.type == 10
                      ? '请输入正确的手机号'
                      : '请输入充值账号',
            required: true
          }
        ],
        captcha: [{ required: true, message: '请输入验证码' }]
      },
      dialog: {
        show: false,
        msg: '',
        ulMsgShow: false
      },
      subBtnDisable: true,
      captchaImg: {
        captchaBase64: '',
        captchaKey: ''
      },
      promptVisible: false
    }
  },
  created() {
    if (this.$route.query.code) {
      this.CheckCode()
    }
    this.rechargeType = this.$route.query.type
    this.init()
    this.getImgCode()
  },
  mounted() {
    document.title = this.rechargeVariable.name
  },
  methods: {
    toCardBag() {
      // 跳转卡包页面
      window.location.href = process.env.VUE_APP_HTTP_KB
    },
    initForm() {
      this.form.phone = ''
      this.form.token = ''
      this.form.captcha = ''
    },
    init() {
      this.rechargeVariable.class =
        this.rechargeType == 1 && this.textReplace != 2
          ? 'recharge-hf'
          : this.rechargeType == 1 && this.textReplace == 2
            ? 'recharge-hf-2'
            : this.rechargeType == 2
              ? 'recharge-tm-gwq'
              : this.rechargeType == 3
                ? 'recharge-tm-cs'
                : this.rechargeType == 4
                  ? 'recharge-elm'
                  : this.rechargeType == 5
                    ? 'recharge-zgsh'
                    : this.rechargeType == 7
                      ? 'recharge-tmyhdhq'
                      : this.rechargeType == 8
                        ? 'recharge-ayq'
                        : this.rechargeType == 9
                          ? 'recharge-yk'
                          : this.rechargeType == 10
                            ? 'recharge-tx'
                            : 'recharge'
      this.rechargeVariable.name =
        this.rechargeType == 1
          ? '话费兑换充值'
          : this.rechargeType == 2
            ? '天猫购物券直充'
            : this.rechargeType == 3
              ? '天猫超市卡直充'
              : this.rechargeType == 4
                ? '饿了么兑换'
                : this.rechargeType == 5
                  ? '中石化加油卡直充'
                  : this.rechargeType == 7
                    ? '天猫优惠兑换券'
                    : this.rechargeType == 8
                      ? '爱奇艺兑换'
                      : this.rechargeType == 9
                        ? '优酷会员直充'
                        : this.rechargeType == 10
                          ? '腾讯视频会员直充'
                          : '商品直充'
    },
    CheckCode() {
      this.subBtnDisable = true
      const obj = {
        code: this.form.code,
        redeemCodePageType: 0
      }
      checkCode(obj).then(res => {
        if (res.code == 0) {
          const data = res.data
          if (data.routeFlag == 1) {
            this.form.token = data.token
            // if (data.phone){
            //     this.form.phone = data.phone;
            // } else{
            // }
            this.subBtnDisable = false
          } else if (data.routeFlag == 2) {
            this.initForm()
            this.dialog.msg = res.message
            for (const k in data) {
              this.dialog[k] = data[k]
            }
            this.dialog.show = true
            this.dialog.ulMsgShow = true
          } else {
            this.initForm()
            this.dialog.msg = '兑换类型不匹配，无法兑换！'
            this.dialog.show = true
          }
        } else {
          this.initForm()
          this.dialog.show = true
          if (res.data && res.data.checkRedeemCodeErrorCode) {
            switch (res.data.checkRedeemCodeErrorCode.code) {
              case -1:
                {
                  this.dialog.msg = '兑换码不存在'
                }
                break
              case 1:
                {
                  this.dialog.msg = '该兑换码在兑换中'
                }
                break
              case 2:
                {
                  this.dialog.msg = '该兑换码正在售后中，无法兑换！'
                }
                break
              case 3:
                {
                  this.dialog.msg = '该兑换码已退款退券，无法兑换！'
                }
                break
              case 4:
                {
                  this.dialog.msg = '该兑换码已冻结，无法兑换！'
                }
                break
              case 5:
                {
                  this.dialog.msg = '该兑换码已使用'
                }
                break
              case 6:
                {
                  this.dialog.msg = '兑换时间已过期'
                }
                break
              case 7:
                {
                  this.dialog.msg = `兑换时间未开始，有效兑换时间：<br>${this.common.formatDate(
                    res.data.effectiveStartTime,
                    '{y}-{m}-{d}'
                  )}~${this.common.formatDate(
                    res.data.effectiveEndTime,
                    '{y}-{m}-{d}'
                  )}`
                }
                break
              case 8:
                {
                  this.dialog.msg = '兑换类型不匹配'
                }
                break
              case 9:
                {
                  this.dialog.msg = '兑换页面类型不匹配'
                }
                break
              case 10:
                {
                  this.dialog.msg = '兑换手机号码信息不存在'
                }
                break
              case 11:
                {
                  this.dialog.msg = '此兑换码已激活'
                }
                break
              default:
                {
                  this.dialog.msg = res.message
                }
                break
            }
          } else {
            this.dialog.msg = res.message
          }
        }
      })
    },
    redeemChange(event) {
      // 兑换码变化
      if (this.form.code) {
        this.CheckCode()
      }
    },
    submit() {
      // 立即兑换
      if (!this.subBtnDisable) {
        if ([6, 9, 10, 8, 13, 14].includes(+this.$route.query.type)) {
          this.promptVisible = true
        } else {
          this.$refs.form.submit()
        }
      }
    },
    submitConfirm() {
      this.promptVisible = false
      this.$refs.form.submit()
    },
    subSuccess() {
      // 表单验证成功后事件
      const obj = {
        account: this.form.phone.trim(),
        captcha: this.form.captcha.trim(),
        token: this.form.token,
        captchaKey: this.captchaImg.captchaKey
      }
      bindAccount(obj).then(res => {
        if (res.code == 0) {
          this.dialog.msg = res.message
          this.dialog.show = true
        } else {
          this.dialog.msg = res.message
          this.dialog.show = true
        }
        this.getImgCode()
      })
    },
    getImgCode() {
      // 获取验证码
      getCaptcha({}).then(res => {
        if (res.code == 0) {
          const { captchaBase64, captchaKey } = res.data
          this.captchaImg = {
            captchaKey,
            captchaBase64: `data:image/png;base64,${captchaBase64}`
          }
        }
      })
    },
    dialogSure() {
      // 关闭弹窗
      this.dialog.show = false
      setTimeout(() => {
        this.dialog.ulMsgShow = false
      }, 200)
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/style/comCouRecStyle.less';
.taoCoyp {
  position: absolute;
  top: 34.8%;
  right: -0px;
  width: 220px;
}
.operations {
  display: flex;
  .cancel {
    background-color: #999;
  }
}
</style>
