<template>
  <div>
    <div class="rule" v-if="textReplace == 2">
      <h2><span>活动说明</span></h2>
      <ul>
        1、话费0元充，高端客户AUM提升有机会获得100-300元档次话费充值券;<br />
        2、活动商品及相关技术由银联商务湖南分公司&易点生活提供；<br />
        3、活动咨询服务电话：400-882-6000；<br />
        4、咨询服务时间：早上9点-晚上9点。<br />
      </ul>
    </div>

    <!-- 规则说明 -->
    <div class="rule">
      <h2><span>兑换规则</span></h2>
      <ul v-if="rechargeType == 1 && textReplace != 2">
        1、请详细核对充值手机号，必须是11位数正确手机号码。充值成功后无法更换和退款，因个人原因导致充值账号错误，本平台概不负责。<br />
        2、空号、锁定期、黑名单、携号转网、部分虚拟运营商号段等不支持充值。<br />
        3、请在有效期内使用，过期将无法使用，不退换不补偿。<br />
        4、充值30分钟内到账，请注意查看运营商短信通知。<br />
        <li v-if="textReplace == 1">
          5、若30分未到账，请重新兑换或联系活动主办方。
        </li>
        <li v-else>
          5、若30分钟未到账，请重新兑换或联系客服，客服电话：
          <a href="tel:4006627299">4006627299</a>
        </li>
      </ul>
      <ul v-else-if="rechargeType == 1 && textReplace == 2">
        1、请详细核对兑换手机号，必须是11位数正确手机号码。兑换成功后无法更换和退款，因个人原因导致兑换手机号错误，本平台概不负责。<br />
        2、请在有效期内使用，过期将无法使用，不退换不补偿。<br />
        3、短信方式接收的兑换码，输入兑换码后将自动显示接收短信的手机号码，且手机号码无法修改。<br />
        4、兑换可能会存在延迟情况，正常30分钟内发放完成。<br />
        5、若30分钟未发放完成，请联系活动主办方。<br />
      </ul>
      <ul v-else-if="rechargeType == 2">
        1、充值时请填写淘宝绑定手机号，请勿填写、邮箱、淘宝账号、淘宝昵称。<br />
        2、充值成功后，购物券有效期29天，过期不退不补，请尽快使用<br />
        3、若30分钟未到账，请重新兑换或联系客服电话：<a href="tel:4006627299"
          >4006627299</a
        >
      </ul>
      <ul v-else-if="rechargeType == 3">
        1、充值时请填写正确的淘宝账号，请勿填写账号绑定的手机号、邮箱、淘宝昵称。<br />
        2、淘宝账号查看方法：打开淘宝APP-我的淘宝-设置-账户与安全-淘宝账号。<br />
        3、充值成功后，天猫超市卡有效期自充值之日起3年内有效。请在有效期内使用，过期将无法使用，不退换不补偿。<br />
        4、充值成功后无法更换和退款，因个人原因导致充值账号错误，本平台概不负责。<br />
        5、若30分钟未到账，请重新兑换或联系客服，客服电话：
        <a href="tel:4006627299">4006627299</a>
        <br />
      </ul>
      <ul v-else-if="rechargeType == 4">
        1、请详细核对充值手机号，必须是11位数正确手机号码。充值后无法更换和退款，因个人原因导致充值账号错误，本平台概不负责。<br />
        2、兑换码在使用前需先登录或注册成为饿了么用户后，再进行激活。<br />
        3、充值可能会存在延迟情况，正常30分钟内到账。<br />
        4、若30分钟未到账，请重新兑换或联系客服，客服电话：
        <a href="tel:4006627299">4006627299</a>
        <br />
      </ul>
      <ul v-else-if="rechargeType == 5">
        1、支持中石化IC卡充值。中石化加油卡为1000开头的19位卡号，填写卡号时请务必填写准确。<br />
        2、充值范围：全国各地的中石化加油卡主卡、记名卡均可充值（不支持不记名卡、副卡充值）。<br />
        3、正常情况十分钟内到账。每日晚上22:50至次日凌晨0:50为中石化官方系统维护时间。所以在此时间兑换的订单会延迟至凌晨1点左右开始充值，请耐心等待。<br />
        4、一张加油卡每天限充9次，单月限充12次，连续充值会导致失败，需要间隔5分钟再充值。<br />
        5、充值成功后：用户须持加油卡至任一中石化加油站对卡内备付金圈存，圈存后方可使用。<br />
        6、充值成功后无法更换和退款，因个人原因导致充值账号错误，本平台概不负责。<br />
        7、若30分钟未到账，请重新兑换或联系客服，客服电话：
        <a href="tel:4006627299">4006627299</a
        ><br />
      </ul>
      <ul v-else-if="rechargeType == 11">
        1、请认真核对需要充值的京东账号，充值后无法更换和退款。
        <span style="color:red"
          >（请输入京东账号名，请勿输入昵称、手机号等相关合作账号）</span
        >，
        充值后无法更换和退款。京东账号名查找路径：京东APP-我的-右上角设置-点击用户跳转即可查看京东账号名。<br />
        2、因个人原因导致充值账号错误，本平台概不负责。<br />
        3、兑换码过期无法使用，请在有效期内充值。<br />
        4、充值可能存在延迟，若30分钟未到账，请重新兑换或联系客服。客服电话：
        <a href="tel:4006627299">4006627299</a>
        <br />
      </ul>
      <ul v-else-if="rechargeType == 7">
        1、绑定时请填写正确的淘宝账号，请勿填写账号绑定的手机号、邮箱、淘宝昵称。<br />
        2、淘宝账号查看方法：打开淘宝APP-我的淘宝-设置-账户与安全-淘宝账号。<br />
        3、绑定成功后优惠券7天内使用有效。请在有效期内使用，过期将无法使用，不退换不补偿。<br />
        4、绑定成功后，可通过手机淘宝APP进入“天猫超市”页面→点击底部“我的”入口→点击页面顶部“优惠券”查看。<br />
        5、绑定成功后无法更换或退款，因个人原因导致绑定账号错误，本平台概不负责。<br />
        6、若30分钟未到账，请重新兑换或联系客服，客服电话：
        <a href="tel:4006627299">4006627299</a>
        <br />
      </ul>
      <ul v-else-if="rechargeType == 8">
        1、兑换前请先登录或注册成为爱奇艺用户后，再进行兑换。<br />
        2、请认真核对手机号并在有效期内兑换，卡密过期将无法使用，不退换不补偿。<br />
        3、兑换成功，进入爱奇艺App-点击页面下方“VIP会员”即可查看会员到账情况。<br />
        4、兑换成功后，周卡会员有效期自激活之日起7天，月卡会员有效期自激活之日起30天，季卡会员有效期自激活之日起90天，半年卡会员有效期自激活之日起180天，年卡会员有效期自激活之日起365天。<br />
        5、多张卡密一起激活，会员有效期则相应顺延。<br />
        6、爱奇艺保留修改会员的使用规则、条款和使用条件的权利，所有规则、条款和条件将在法律允许的最大限度内适用。<br />
        7、电子券在使用过程中遇到疑问，请致电易点生活客服热线：
        <a href="tel:4006627299">4006627299</a>
        <br />
      </ul>
      <ul v-else-if="rechargeType == 12">
        1、兑换码过期无法使用，请在有效期内充值。<br />
        2、请认真核对需要充值的手机号，充值后无法更换和退款。因个人原因导致充值手机号错误，本平台概不负责。<br />
      </ul>
      <ul v-else-if="rechargeType == 13">
        1、充值时请填写正确的京东账号名，仅限京东注册账号充值，请勿填写昵称或其他账号（查看路径：京东APP-我的-账户设置-账号名）<br />
        2、请先完成注册，获得京东账号名。首次注册需实名认证，信息填写无误后方可进行充值。<br />
        3、充值成功后，有效期自充值之日起3年内有效。<br />
        4、如有疑问请联系易点生活客服热线：
        <a href="tel:4006627299">4006627299</a>
      </ul>
      <ul v-else-if="rechargeType == 14">
        1、兑换码过期无法使用，请在有效期内充值。<br />
        2、请认真核对需要充值的手机号，充值后无法更换和退款。因个人原因导致充值手机号错误，本平台概不负责。<br />
        3、充值可能存在延迟，若30分钟未到账，请重新兑换或联系商家。
      </ul>
      <ul v-else>
        1、兑换码过期无法使用，请在有效期内充值。<br />
        2、请认真核对需要充值的充值账号，充值后无法更换和退款。因个人原因导致充值充值账号错误，本平台概不负责。<br />
        3、充值可能存在延迟，若30分钟未到账，请重新兑换或联系客服。客服电话：
        <a href="tel:4006627299">4006627299</a>
        <br />
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      textReplace: Number(this.$route.query.t) === 1,
      // 1.话费充值;2.天猫购物券;3:天猫超市享淘卡;4:饿了么吃货卡;5:中国石化加油卡;6：公共；7:天猫优惠券兑换;8:爱奇艺;9:优酷；10:腾讯；11:京东
      rechargeType: Number(this.$route.query.type)
    }
  }
}
</script>
